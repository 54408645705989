<template>
  <div class="c--footer"  v-if="isDisplayFooter">
    <div class="content-footer">
      <div class="f-first">
        <img class="logo" alt="{{ $t('_public._name_app') }}" src="../assets/logo.png">
        <div class="info-app-footer">
          <p>{{ $t('_public._All_Rights_Reserved') }}</p>
          <h3 class="name-app">{{ $t('_public._name_app') }}</h3>
        </div>

      </div>
      <div class="f-second">
        <div class="item">
          <span>{{ $t('_footer._terms_of_use') }}</span>
          <a class="btn-item" href="https://scard9.com/aaa/privacy.html" target="_blank">
            {{ $t('_footer._press_here') }}
          </a>
        </div>
        <div  class="item">
          <span>{{ $t('_footer._programming_and_development_company') }}</span>
          <a class="btn-item" href="https://www.bros-app.com/" target="_blank">
            {{ $t('_footer._bros_app') }}
          </a>
        </div>
      </div>
      <div class="f-third">
        <div class="item">
          <ion-icon class="ic" name="logo-whatsapp"></ion-icon>
          <span>{{ $t('_public._app_phone') }}</span>
        </div>
        <div class="item">
          <ion-icon class="ic" name="mail-outline"></ion-icon>
          <span>{{ $t('_public._app_email') }}@scard9.com</span>
        </div>
        <!-- <div class="item">
          <ion-icon class="ic" name="logo-whatsapp"></ion-icon>
          <span>whatsapp</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { isDisplayFooter } from '@/components/sidebar/state';

export default {
  name: 'FooterBar',
  props: {
    msg: String
  },
  setup() {
    return { 
      isDisplayFooter
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c--footer {
  position: relative;
}
.content-footer {
  position: relative;
  min-height: 65px;
  padding: 16px;
  background: var(--box-background);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.f-first {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.f-first .logo {
  width: 32px;
}
.f-first .info-app-footer{
  padding: 0px 12px;
  text-align: start;
}
.f-first .info-app-footer p {
  font-size: 12px;
  color: var(--color-text);
}
.f-first .info-app-footer h3 {
  font-size: 12px;
  color: var(--color-text);
}
.f-second .item {
  font-size: 12px;
  color: var(--color-text);
}
.f-second .item .btn-item {
  padding-inline-start: 5px;
  text-decoration: none;
  color: var(--public);
}
.f-third {
  text-align: start;
}
.f-third .item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 12px;
  direction: ltr;
}
.f-third .item .ic {
  color: var(--color-text);
}
.f-third .item span {
  padding: 0px 5px;
  color: var(--color-text);
}




@media (max-width: 992px) {
  .content-footer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .f-first,
  .f-second,
  .f-third {
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
  }
  .f-third {
    margin-bottom: unset;
    text-align: center;
  }
  .f-third .item {
    justify-content: center;
  }
}
</style>
